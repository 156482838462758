import { DefaultLayout } from "./DefaultLayout";

const CONTACT_MAIL = "contact@moneta.studio";

export const ErrorPage = ({ status, title }: { status: number; title: string }) => (
  <DefaultLayout>
    <div className="mt-6 flex w-full max-w-screen-md flex-col gap-6 self-center">
      <span className="mx-auto flex h-[250px] items-center text-[340px]">{status}</span>
      <span className="text-primaryText mx-auto text-2xl font-normal tracking-tight">{title}</span>
      <div className="ml-[50%] w-dvw -translate-x-1/2 border-b border-black" />
      <span className="text-secondaryText mx-auto text-center text-lg font-normal tracking-tight">
        Make sure the URL you used is correct. <br />
        If everything is okay and you still encounter this problem, contact us at{" "}
        <a className="text-primaryText" href={`mailto:${CONTACT_MAIL}`}>
          {CONTACT_MAIL}
        </a>
      </span>
    </div>
  </DefaultLayout>
);
